import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Container,
  Button,
  TextField,
  Input
} from '@mui/material';
import EditField from '@/components/Inputs/EditField/EditField';
import EditAutocompleteField from '@/components/Inputs/EditAutocompleteField/EditAutocompleteField';
import { API_URL } from '@/config';

const CalendarView = ({ id, fetchEvents, onClose }) => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editFields, setEditFields] = useState<any>({});
  const [successMessage, setSuccessMessage] = useState(false);

  const [statuses, setStatuses] = useState([]);
  const [socialMedias, setSocialMedias] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(API_URL+ `/event/${id}`);
        setEvent(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener el evento', error);
        setLoading(false);
      }
    };

    const fetchAutoCompleteData = async () => {
      try {
        const [statusesRes, socialMediasRes, typesRes, usersRes] = await Promise.all([
          axios.get(API_URL+ '/event-status'),
          axios.get(API_URL+ '/event-social-media'),
          axios.get(API_URL+ '/event-type'),
          axios.get(API_URL+ '/user')
        ]);

        setStatuses(statusesRes.data);
        setSocialMedias(socialMediasRes.data);
        setTypes(typesRes.data);
        setUsers(usersRes.data);
      } catch (error) {
        console.error('Error al obtener los datos de autocompletado:', error);
      }
    };

    fetchEvent();
    fetchAutoCompleteData();
  }, [id]);

  const handleFieldChange = (field, value) => {
    setEditFields({ ...editFields, [field]: value });
    setEvent({ ...event, [field]: value });
  };

  const handleFileChange = (e) => {
    setMediaFiles(e.target.files);
  };

  const handleSubmit = async () => {
    try {
      const sendData = { ...editFields };

      const response = await axios.put(API_URL+ `/event/${id}`, sendData, {
        headers: { 'Content-Type': 'application/json' },
      });

      const eventId = response.data.id;

      if (mediaFiles.length > 0) {
        const formData = new FormData();
        for (let i = 0; i < mediaFiles.length; i++) {
          formData.append('files', mediaFiles[i]);
        }
        formData.append('eventId', eventId);

        await axios.post(API_URL+ '/event-media/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      setEvent(response.data);
      setEditFields({});
      setSuccessMessage(true);
      setTimeout(() => setSuccessMessage(false), 3000);
      fetchEvents();
    } catch (error) {
      console.error('Error al actualizar el evento', error);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!event) {
    return (
      <Container>
        <Typography variant="h5" color='rgba(0,0,0,0.4)'>Selecciona un evento</Typography>
      </Container>
    );
  }

  return (
    <Card elevation={8} sx={{ borderRadius: 5, maxWidth: 800 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <EditField
            label="Título"
            value={event.title}
            onSave={(value) => handleFieldChange('title', value)}
            isEdited={editFields?.title || null}
          />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EditField
              label="Descripción"
              value={event.description}
              onSave={(value) => handleFieldChange('description', value)}
              isEdited={editFields?.description || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Fecha"
              value={event.date}
              onSave={(value) => handleFieldChange('date', value)}
              type="date"
              isEdited={editFields?.date || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Hora"
              value={event.time}
              onSave={(value) => handleFieldChange('time', value)}
              type="time"
              isEdited={editFields?.time || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditAutocompleteField
              label="Estado"
              value={event.status}
              options={statuses.map(option => option.name)}
              onSave={(value) => handleFieldChange('status', value)}
              isEdited={editFields?.status || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditAutocompleteField
              label="Red Social"
              value={event.socialMedia}
              options={socialMedias.map(option => option.name)}
              onSave={(value) => handleFieldChange('socialMedia', value)}
              isEdited={editFields?.socialMedia || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditAutocompleteField
              label="Tipo"
              value={event.type}
              options={types.map(option => option.name)}
              onSave={(value) => handleFieldChange('type', value)}
              isEdited={editFields?.type || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Nota"
              value={event.note}
              onSave={(value) => handleFieldChange('note', value)}
              isEdited={editFields?.note || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditAutocompleteField
              label="Responsable"
              value={event.userId}
              options={users.map(option => `${option.first_name} ${option.last_name}`)}
              onSave={(value) => handleFieldChange('userId', value)}
              isEdited={editFields?.userId || null}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              type="file"
              inputProps={{ multiple: true }}
              onChange={handleFileChange}
            />
          </Grid>
          <Grid item xs={12} container justifyContent={'flex-end'} mb={3}>
            {Object.keys(editFields).length > 0 && (
              <Typography variant="body2" color="error" marginLeft={2} mt={2}>
                Necesitas guardar los cambios
              </Typography>
            )}
            {successMessage && (
              <Typography variant="body2" color="success" marginLeft={2} mt={2}>
                Guardado con éxito
              </Typography>
            )}
          </Grid>
          <Grid container item xs={12} display="flex" alignItems="center" justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="contained" color="inherit" onClick={onClose}>
                Cerrar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSubmit} style={{ width: 200 }}>
                Guardar Cambios
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CalendarView;
