import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';

const ImageEditor = ({ images, onImagesChange, getImageUrl, singleImage = false }) => {
  const [localImages, setLocalImages] = useState(images);

  const onDrop = useCallback((acceptedFiles) => {
    const newImages = acceptedFiles.map(file => ({
      id: URL.createObjectURL(file),
      file,
    }));

    let updatedImages;
    if (singleImage) {
      updatedImages = newImages;
    } else {
      updatedImages = [...localImages, ...newImages];
    }

    setLocalImages(updatedImages);
    onImagesChange(updatedImages);
  }, [localImages, onImagesChange, singleImage]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveImage = (index) => {
    const updatedImages = localImages.filter((_, i) => i !== index);
    setLocalImages(updatedImages);
    onImagesChange(updatedImages);
  };

  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData('draggedImageIndex', index);
  };

  const handleDrop = (index) => (event) => {
    event.preventDefault();
    const draggedImageIndex = parseInt(event.dataTransfer.getData('draggedImageIndex'), 10);
    if (draggedImageIndex !== index) {
      const updatedImages = [...localImages];
      const [draggedImage] = updatedImages.splice(draggedImageIndex, 1);
      updatedImages.splice(index, 0, draggedImage);
      setLocalImages(updatedImages);
      onImagesChange(updatedImages);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container spacing={2}>
      {localImages.map((image, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={image.id}
          style={{ position: 'relative' }}
          draggable
          onDragStart={handleDragStart(index)}
          onDrop={handleDrop(index)}
          onDragOver={handleDragOver}
        >
          <img
            src={image.file ? image.id : getImageUrl(image)}
            alt="Property"
            style={{ width: '100%', marginBottom: '1rem', cursor: 'move' }}
          />
          <IconButton
            style={{ position: 'absolute', top: 8, right: 8 }}
            onClick={() => handleRemoveImage(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      ))}
      {!singleImage || localImages.length === 0 ? (
        <Grid item xs={12} sm={6} md={4} {...getRootProps()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dashed #ccc', cursor: 'pointer', height: 150 }}>
          <input {...getInputProps()} />
          <AddIcon style={{ fontSize: 48 }} />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ImageEditor;
