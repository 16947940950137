import React from 'react'

const logoSrc = require('@/assets/img/logo/logo.webp')

const Logo = ({ width, height, style }: any) => {
    return (
        <img
            src={logoSrc}
            style={{ width, height, ...style, objectFit:'contain' }}
        />
    )
}

export default Logo