import React, { useEffect, useState } from 'react';
import { useAuth } from '@/provider/AuthProvider';
import { Button, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import TotalBalanceWidget from '@/components/Widget/finance/TotalBalanceWidget/TotalBalanceWidget';
import { getTotalBalance } from '@/api/finance/getTotalBalance';
import TransactionSummaryWidget from '@/components/Widget/finance/TransactionSummaryWidget/TransactionSummaryWidget ';
import { Root, RootBody, RootContent, RootWidget } from './Dashboard.style';


const Dahsboard = ({
    setShowDashboard
}) => {
    const [message, setMessage] = useState(null);
    const [totalBalance, setTotalBalance] = useState(0);

    const auth = useAuth();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const navigation = useNavigate();

    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const fetchTotalBalance = async () => {
            try {
                const response = await getTotalBalance(auth.user.id);
                console.log("response", response)
                setTotalBalance(response.data.totalBalance);
            } catch (error) {
                setMessage('Failed to fetch total balance');
            }
        };

        if (auth.user?.id) {
            fetchTotalBalance();
        }
    }, [auth.user]);

    return (
        <>
            <Root>
                <RootBody>
                    <RootContent>
                        <Typography variant="h2" mb={3}>
                            Finanzas
                        </Typography>
                        <Button variant='outlined' onClick={() => setShowDashboard(false)}>Lista</Button>
                    </RootContent>
                    <RootWidget>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                                <TotalBalanceWidget total={totalBalance} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TransactionSummaryWidget />
                            </Grid>
                        </Grid>
                    </RootWidget>
                </RootBody>
            </Root>
        </>
    );
};

export default Dahsboard;
