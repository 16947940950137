import React, { useEffect, useState } from 'react'
import { Root, RootBody, RootContent, RootModal, RootSideBody, RootsideBodyTop, RootTable } from './Account.style'
import { useAuth } from '@/provider/AuthProvider'
import { Button, Grid, Modal, Snackbar, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import TableOrders from '@/modules/home/TableOrders/TableOrders'
import Table from '@/modules/account/Table/Table'
import Form from '@/modules/account/Form/Form'
import axios from 'axios'
import MenuIcon from '@/components/Icons/MenuIcon'
import CloseIcon from '@/components/Icons/CloseIcon'
import WindowIcon from '@/components/Icons/WindowIcon'
import RightScreenIcon from '@/components/Icons/RightScreenIcon'
import UserView from '@/modules/account/UserView/UserView'
import { API_URL } from '@/config'


const Account = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [activeCreate, setActiveCreate] = useState(false);
  const [inModal, setInModal] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(API_URL+ '/user');
      console.log("response", response.data)
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching properties', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);


  const handleRowClick = async (id) => {
    setSelectedProperty(id);
  };

  const handleClickAdd = () => {
    setActiveCreate(true)
    setSelectedProperty(null)
  }

  const handleOnClose = () => {
    setSelectedProperty(null)
    setActiveCreate(false)
  }

  return (
    <>
      <Root>
        <RootBody>
          <RootContent>
            <Typography variant='h2'>Accesos</Typography>
            <Button variant='outlined' onClick={handleClickAdd} >Crear usuario</Button>
          </RootContent>
          <RootTable> 
            <Table
              loading={loading}
              users={users}
              onClickPencil={handleRowClick}
            />
          </RootTable>
        </RootBody>
        {
          activeCreate && !selectedProperty
            ? !inModal
              ? (<CreateForm
                onClose={() => handleOnClose()}
                onClickWindow={() => setInModal(true)}
                inModal={inModal}
                fetchUsers={fetchUsers} />)
              : (<Modal open={true}>
                <RootModal>
                  <CreateForm
                    onClose={() => handleOnClose()}
                    onClickWindow={() => setInModal(false)}
                    inModal={inModal}
                    fetchUsers={fetchUsers} />
                </RootModal>
              </Modal>
              )
            : null

        }
        {
          selectedProperty
            ? !inModal
              ? (<PropertyCard
                onClose={() => setSelectedProperty(null)}
                onClickWindow={() => setInModal(true)}
                inModal={inModal}
                fetchUsers={fetchUsers}
                selectedProperty={selectedProperty} />)
              : (<Modal open={true}>
                <RootModal>
                  <PropertyCard
                    onClose={() => setSelectedProperty(null)}
                    onClickWindow={() => setInModal(false)}
                    inModal={inModal}
                    fetchUsers={fetchUsers}
                    selectedProperty={selectedProperty} />
                </RootModal>
              </Modal>
              )
            : null
        }
      </Root>
    </>
  )
}

const PropertyCard = ({ fetchUsers, selectedProperty, onClickWindow, onClose, inModal }) => {
  return (
    <RootSideBody>
      <RootsideBodyTop>
        <Typography variant='h2'>Propiedad</Typography>
        <Grid container flexDirection={'column'} maxWidth={160}>
          <Grid item xs={6} container justifyContent={'center'} alignItems={'center'}>
            {
              !inModal
                ? <WindowIcon onClick={onClickWindow} style={{ width: 28, top: 4, position: 'relative' }} />
                : <RightScreenIcon onClick={onClickWindow} style={{ width: 22, top: 7, position: 'relative' }} />
            }
          </Grid>
          <Grid item xs={6}>
            <CloseIcon onClick={onClose} style={{ width: 50 }} />
          </Grid>
        </Grid>
      </RootsideBodyTop>
      <UserView id={selectedProperty} fetchUsers={fetchUsers} onClose={onClose}/>
    </RootSideBody>
  )
}

const CreateForm = ({ fetchUsers, onClickWindow, onClose, inModal }) => {
  return (
    <RootSideBody>
      <RootsideBodyTop>
        <Typography variant='h2'>Crear</Typography>
        <Grid container flexDirection={'column'}
          maxWidth={160}>
          <Grid item xs={6} container justifyContent={'center'} alignItems={'center'}>
            {
              !inModal
                ? <WindowIcon onClick={onClickWindow} style={{ width: 28, top: 4, position: 'relative' }} />
                : <RightScreenIcon onClick={onClickWindow} style={{ width: 22, top: 7, position: 'relative' }} />
            }
          </Grid>
          <Grid item xs={6}>
            <CloseIcon onClick={onClose} style={{ width: 50 }} />
          </Grid>
        </Grid>
      </RootsideBodyTop>
      <Form fetchUsers={fetchUsers} />
    </RootSideBody>
  )
}

export default Account