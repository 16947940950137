import React, { useEffect, useState } from 'react';
import { Root, RootContent, RootWidget } from './index.style';
import { Grid, Typography, CircularProgress } from '@mui/material';
import Card from '@/components/Cards/Card/Card';
import axios from 'axios';
import { API_URL } from '@/config';

const Setting = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(API_URL+ '/user-config-type');
        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching settings', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  if (loading) {
    return (
      <Root>
        <RootContent>
          <CircularProgress />
        </RootContent>
      </Root>
    );
  }

  return (
    <>
      <Root>
        <RootContent>
          <Typography variant="h2" mb={3}>
            Ajustes
          </Typography>
          <RootWidget>
            <Grid container spacing={3}>
              {settings.map((setting, index) => (
                <Grid item xs={6} sm={6} md={4} key={index}>
                  <Card>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant='body1'>{setting.name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='body1'>{setting.description}</Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </RootWidget>
        </RootContent>
      </Root>
    </>
  );
};

export default Setting;
