import React, { useEffect, useState } from 'react';
import { Card, Grid, Typography, List, ListItem, ListItemText, Divider, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { separadorMiles } from '@/utils/separadorMiles';
import { API_URL } from '@/config';

const TransactionSummaryWidget = () => {
  const [transactions, setTransactions] = useState([]);
  const [type, setType] = useState('recent');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories for dropdown
    axios.get(API_URL+ '/finance-categories')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    let url = API_URL+`/finance/finance/transactions?type=${type}`;
    if (type === 'category' && category) {
      url += `&category=${category}`;
    }
    axios.get(url)
      .then(response => setTransactions(response.data))
      .catch(error => console.error('Error fetching resume:', error));
  }, [type, category]);

  return (
    <Card style={{ borderRadius: 20 }} elevation={10}>
      <Grid container mt={3} mb={3}>
        <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} mb={2}>
          <Typography variant='h5'><b>Resumen de Transacciones</b></Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Select summary type' }}
          >
            <MenuItem value="recent">Más Recientes</MenuItem>
            <MenuItem value="highest">Mayor Monto</MenuItem>
            <MenuItem value="category">Por Categoría</MenuItem>
          </Select>
          {type === 'category' && (
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Select category' }}
            >
              {categories.map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>{cat.id}</MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item xs={12}>
          <List>
            {transactions.map((transaction, index) => (
              <React.Fragment key={transaction.id}>
                <ListItem>
                  <ListItemText
                    primary={`${transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)} - ${transaction.category}`}
                    secondary={`$ ${separadorMiles(transaction.amount)} - ${new Date(transaction.date).toLocaleDateString()}`}
                  />
                </ListItem>
                {index < transactions.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TransactionSummaryWidget;
