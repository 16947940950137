import React from 'react'
import { Root, RootBody, RootContent, RootModal, RootSideBody, RootsideBodyTop, RootTable } from './FinanceEdit.style'
import { Button, Grid, Modal, Typography } from '@mui/material';
import WindowIcon from '@/components/Icons/WindowIcon';
import CloseIcon from '@/components/Icons/CloseIcon';
import RightScreenIcon from '@/components/Icons/RightScreenIcon';
import FinanceView from '../FinanceView/FinanceView';
import Form from '../Form/Form';
import Table from '../Table/Table';

const FinanceEdit = ({
    activeCreate,
    selectedFinance,
    inModal,
    fetchFinances,
    handleOnClose,
    setInModal,
    setSelectedFinance,
    loading,
    finances,
    handleRowClick,
    handleClickAdd
}) => {
    return (
        <Root>
            <RootBody>
                <RootContent>
                    <Typography variant='h2'>Finanzas</Typography>
                    <Button variant='outlined' onClick={handleClickAdd}>Crear</Button>
                </RootContent>
                <RootTable>
                    <Table
                        loading={loading}
                        finances={finances}
                        onClickPencil={handleRowClick}
                    />
                </RootTable>
            </RootBody>
            {activeCreate && !selectedFinance ? (
                !inModal ? (
                    <CreateForm
                        onClose={() => handleOnClose()}
                        onClickWindow={() => setInModal(true)}
                        inModal={inModal}
                        fetchFinances={fetchFinances}
                    />
                ) : (
                    <Modal open={true}>
                        <RootModal>
                            <CreateForm
                                onClose={() => handleOnClose()}
                                onClickWindow={() => setInModal(false)}
                                inModal={inModal}
                                fetchFinances={fetchFinances}
                            />
                        </RootModal>
                    </Modal>
                )
            ) : null}
            {selectedFinance ? (
                !inModal ? (
                    <FinanceCard
                        onClose={() => setSelectedFinance(null)}
                        onClickWindow={() => setInModal(true)}
                        inModal={inModal}
                        fetchFinances={fetchFinances}
                        selectedFinance={selectedFinance}
                    />
                ) : (
                    <Modal open={true}>
                        <RootModal>
                            <FinanceCard
                                onClose={() => setSelectedFinance(null)}
                                onClickWindow={() => setInModal(false)}
                                inModal={inModal}
                                fetchFinances={fetchFinances}
                                selectedFinance={selectedFinance}
                            />
                        </RootModal>
                    </Modal>
                )
            ) : null}
        </Root>
    );
};

const FinanceCard = ({ fetchFinances, selectedFinance, onClickWindow, onClose, inModal }) => {
    return (
        <RootSideBody>
            <RootsideBodyTop>
                <Typography variant='h2'>Detalle</Typography>
                <Grid container flexDirection={'column'} maxWidth={160}>
                    <Grid item xs={6} container justifyContent={'center'} alignItems={'center'}>
                        {
                            !inModal
                                ? <WindowIcon onClick={onClickWindow} style={{ width: 28, top: 4, position: 'relative' }} />
                                : <RightScreenIcon onClick={onClickWindow} style={{ width: 22, top: 7, position: 'relative' }} />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <CloseIcon onClick={onClose} style={{ width: 50 }} />
                    </Grid>
                </Grid>
            </RootsideBodyTop>
            <FinanceView id={selectedFinance} fetchFinances={fetchFinances} onClose={onClose} />
        </RootSideBody>
    )
}

const CreateForm = ({ fetchFinances, onClickWindow, onClose, inModal }) => {
    return (
        <RootSideBody>
            <RootsideBodyTop>
                <Typography variant='h2'>Crear</Typography>
                <Grid container flexDirection={'column'}
                    maxWidth={160}>
                    <Grid item xs={6} container justifyContent={'center'} alignItems={'center'}>
                        {
                            !inModal
                                ? <WindowIcon onClick={onClickWindow} style={{ width: 28, top: 4, position: 'relative' }} />
                                : <RightScreenIcon onClick={onClickWindow} style={{ width: 22, top: 7, position: 'relative' }} />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <CloseIcon onClick={onClose} style={{ width: 50 }} />
                    </Grid>
                </Grid>
            </RootsideBodyTop>
            <Form fetchFinances={fetchFinances} />
        </RootSideBody>
    )
}

export default FinanceEdit