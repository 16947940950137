import React, { useState } from 'react'
import { Root, RootBarline, RootLogo, RootPerfilContainer, RootPerfilMenu, toolTipPopper } from './MenuHeader.style'
import { useNavigate } from 'react-router-dom';
import { Avatar, Tooltip, Typography } from '@mui/material';
import { useAuth } from '@/provider/AuthProvider';

import MenuExpand from './MenuExpand/MenuExpand';
import MenuMobile from './MenuMobile/MenuMobile';
import Logo from '@/components/Logo/Logo';
import BellIcon from '@/components/Icons/BellIcon';
import useIsMobile from '@/hooks/useIsMobile';


interface Props {
  hidePerfil?: any
  setOpenModalPerfilML?: any
}

const MenuHeader: React.FC<Props> = ({
  hidePerfil,
  setOpenModalPerfilML,
}) => {
  const [showMenuMobile, setShowMenuMobile] = useState(false)
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  return (
    <Root>
      <LogoSvg />
      {
        isMobile
          ? <BellIcon style={{ width: 30 }} onClick={() => navigate('/notify')} />
          : <RootPerfilContainer>
            {!hidePerfil && <PerfilMenu setOpenModalPerfilML={setOpenModalPerfilML} />}
          </RootPerfilContainer>
      }
      <BarlineSvg />
    </Root>
  )
}

const PerfilMenu = ({
  setOpenModalPerfilML
}) => {
  const navigate = useNavigate()
  const auth = useAuth()

  const logOut = () => {
    auth.signout(() => {
      navigate('/')
    })
  }

  return (
    <Tooltip placement='bottom-end'
      title={<MenuExpand setOpenModalPerfilML={setOpenModalPerfilML} logOut={logOut} />} classes={{ tooltip: toolTipPopper }}>
      <RootPerfilMenu>
        <div>
          <Typography variant='h4'>Hola {auth.user?.first_name}</Typography>
        </div>
        <Avatar
          style={{
            width: 50,
            height: 50
          }}
        />
      </RootPerfilMenu>
    </Tooltip>
  )
}

const LogoSvg = () => {
  return (
    <RootLogo>
      <Logo
        width={100}
        height={100}
      />
    </RootLogo>
  )
}

const BarlineSvg = () => {
  return (
    <RootBarline>
      <svg width="100%" height="14" viewBox="0 0 100% 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="14" fill="#A6B0B4" />
      </svg>
    </RootBarline>
  )
}

export default MenuHeader