import React, { useEffect, useState } from 'react'
import { Root, RootBody, RootContent, RootModal, RootSideBody, RootsideBodyTop, RootTable } from './index.style'
import { Button, Card, CardContent, Grid, Modal, Typography } from '@mui/material'
import CalendarTable from '@/modules/calendar/CalendarTable/CalendarTable'
import CalendarView from '@/modules/calendar/CalendarView/CalendarView'
import CloseIcon from '@/components/Icons/CloseIcon'
import WindowIcon from '@/components/Icons/WindowIcon'
import RightScreenIcon from '@/components/Icons/RightScreenIcon'
import axios from 'axios';
import Form from '@/modules/calendar/Form/Form'
import { API_URL } from '@/config'

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [activeCreate, setActiveCreate] = useState(false);
  const [inModal, setInModal] = useState(false);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(API_URL+ '/event');
      console.log("response", response.data)
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleRowClick = async (id) => {
    setSelectedEvent(id);
  };

  const handleClickAdd = () => {
    setActiveCreate(true)
    setSelectedEvent(null)
  }

  const handleOnClose = () => {
    setSelectedEvent(null)
    setActiveCreate(false)
  }

  return (
    <>
      <Root>
        <RootBody>
          <RootContent>
            <Typography variant='h2'>Calendario</Typography>
            <Button variant='outlined' onClick={handleClickAdd}>Crear</Button>
          </RootContent>
          <RootTable>
            <Card elevation={8} sx={{ borderRadius: 5 }}>
              <CardContent>
                <CalendarTable onClick={handleRowClick} />
              </CardContent>
            </Card>
          </RootTable>
        </RootBody>
        {
          activeCreate && !selectedEvent
            ? !inModal
              ? (<CreateForm
                onClose={() => handleOnClose()}
                onClickWindow={() => setInModal(true)}
                inModal={inModal}
                fetchEvents={fetchEvents} />)
              : (<Modal open={true}>
                <RootModal>
                  <CreateForm
                    onClose={() => handleOnClose()}
                    onClickWindow={() => setInModal(false)}
                    inModal={inModal}
                    fetchEvents={fetchEvents} />
                </RootModal>
              </Modal>
              )
            : null
        }
        {
          selectedEvent
            ? !inModal
              ? (<EventCard
                onClose={() => setSelectedEvent(null)}
                onClickWindow={() => setInModal(true)}
                inModal={inModal}
                fetchEvents={fetchEvents}
                selectedEvent={selectedEvent} />)
              : (<Modal open={true}>
                <RootModal>
                  <EventCard
                    onClose={() => setSelectedEvent(null)}
                    onClickWindow={() => setInModal(false)}
                    inModal={inModal}
                    fetchEvents={fetchEvents}
                    selectedEvent={selectedEvent} />
                </RootModal>
              </Modal>
              )
            : null
        }
      </Root>
    </>
  )
}

const EventCard = ({ fetchEvents, selectedEvent, onClickWindow, onClose, inModal }) => {
  return (
    <RootSideBody>
      <RootsideBodyTop>
        <Typography variant='h2'>Detalle</Typography>
        <Grid container flexDirection={'column'} maxWidth={160}>
          <Grid item xs={6} container justifyContent={'center'} alignItems={'center'}>
            {
              !inModal
                ? <WindowIcon onClick={onClickWindow} style={{ width: 28, top: 4, position: 'relative' }} />
                : <RightScreenIcon onClick={onClickWindow} style={{ width: 22, top: 7, position: 'relative' }} />
            }
          </Grid>
          <Grid item xs={6}>
            <CloseIcon onClick={onClose} style={{ width: 50 }} />
          </Grid>
        </Grid>
      </RootsideBodyTop>
      <CalendarView id={selectedEvent} fetchEvents={fetchEvents} onClose={onClose} />
    </RootSideBody>
  )
}

const CreateForm = ({ fetchEvents, onClickWindow, onClose, inModal }) => {
  return (
    <RootSideBody>
      <RootsideBodyTop>
        <Typography variant='h2'>Crear</Typography>
        <Grid container flexDirection={'column'} maxWidth={160}>
          <Grid item xs={6} container justifyContent={'center'} alignItems={'center'}>
            {
              !inModal
                ? <WindowIcon onClick={onClickWindow} style={{ width: 28, top: 4, position: 'relative' }} />
                : <RightScreenIcon onClick={onClickWindow} style={{ width: 22, top: 7, position: 'relative' }} />
            }
          </Grid>
          <Grid item xs={6}>
            <CloseIcon onClick={onClose} style={{ width: 50 }} />
          </Grid>
        </Grid>
      </RootsideBodyTop>
      <Form fetchEvents={fetchEvents} />
    </RootSideBody>
  )
}

export default Calendar;
