import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Container,
  Button,
  Switch,
  FormControlLabel
} from '@mui/material';
import EditField from '@/components/Inputs/EditField/EditField';
import EditAutocompleteField from '@/components/Inputs/EditAutocompleteField/EditAutocompleteField'; // Asegúrate de tener este componente importado correctamente
import { getFinanceCategories, getFinancePaymentMethods, getFinanceTypes } from '@/api/finance/autoCompleteService';
import { API_URL } from '@/config';

const FinanceView = ({ id, fetchFinances, onClose }) => {
  const [finance, setFinance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editFields, setEditFields] = useState<any>({});
  const [successMessage, setSuccessMessage] = useState(false);

  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const fetchAutoCompleteData = async () => {
    try {
      const typesResponse = await getFinanceTypes();
      setTypes(typesResponse.data.filter(type => type && type.name));

      const categoriesResponse = await getFinanceCategories();
      setCategories(categoriesResponse.data.filter(category => category && category.name));

      const paymentMethodsResponse = await getFinancePaymentMethods();
      setPaymentMethods(paymentMethodsResponse.data.filter(paymentMethod => paymentMethod && paymentMethod.name));
    } catch (error) {
      console.error('Error fetching autocomplete data:', error);
    }
  };
  useEffect(() => {

    fetchAutoCompleteData();
  }, []);

  useEffect(() => {
    const fetchFinance = async () => {
      try {
        const response = await axios.get(API_URL+ `/finance/${id}`);
        setFinance(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos financieros', error);
        setLoading(false);
      }
    };

    fetchFinance();
  }, [id]);

  const handleFieldChange = (field, value) => {
    setEditFields({ ...editFields, [field]: value });
    setFinance({ ...finance, [field]: value });
  };

  const handleSubmit = async () => {
    try {
      const sendData = { ...editFields };

      const response = await axios.put(API_URL+ `/finance/${id}`, sendData, {
        headers: { 'Content-Type': 'application/json' },
      });

      setFinance(response.data);
      setEditFields({});
      setSuccessMessage(true);
      setTimeout(() => setSuccessMessage(false), 3000);
      fetchFinances();
      fetchAutoCompleteData();
    } catch (error) {
      console.error('Error al actualizar los datos financieros', error);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!finance) {
    return (
      <Container>
        <Typography variant="h5" color='rgba(0,0,0,0.4)'>Selecciona una transacción</Typography>
      </Container>
    );
  }

  return (
    <Card elevation={8} sx={{ borderRadius: 5, maxWidth: 800 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <EditField
            label="Fecha"
            value={finance.date}
            onSave={(value) => handleFieldChange('date', value)}
            type="date"
            isEdited={editFields?.date || null}
          />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EditAutocompleteField
              label="Tipo"
              value={finance.type}
              options={types.map(option => option.name)}
              onSave={(value) => handleFieldChange('type', value)}
              isEdited={editFields?.type || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditAutocompleteField
              label="Categoría"
              value={finance.category}
              options={categories.map(option => option.name)}
              onSave={(value) => handleFieldChange('category', value)}
              isEdited={editFields?.category || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Monto"
              value={finance.amount}
              onSave={(value) => handleFieldChange('amount', Number(value))}
              type="number"
              isEdited={editFields?.amount || null}
              isPrice
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Descripción"
              value={finance.description}
              onSave={(value) => handleFieldChange('description', value)}
              isEdited={editFields?.description || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditAutocompleteField
              label="Método de Pago"
              value={finance.payment_method}
              options={paymentMethods.map(option => option.name)}
              onSave={(value) => handleFieldChange('payment_method', value)}
              isEdited={editFields?.payment_method || null}
            />
          </Grid>
          <Grid item xs={12} container justifyContent={'flex-end'} mt={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={finance.income}
                  onChange={(event) => handleFieldChange('income', event.target.checked)}
                  color="primary"
                />
              }
              label="Inversión"
            />
          </Grid>
          <Grid item xs={12} container justifyContent={'flex-end'} mb={3}>
            {Object.keys(editFields).length > 0 && (
              <Typography variant="body2" color="error" marginLeft={2} mt={2}>
                Necesitas guardar los cambios
              </Typography>
            )}
            {successMessage && (
              <Typography variant="body2" color="success" marginLeft={2} mt={2}>
                Guardado con éxito
              </Typography>
            )}
          </Grid>
          <Grid container item xs={12} display="flex" alignItems="center" justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="contained" color="inherit" onClick={onClose}>
                Cerrar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSubmit} style={{ width: 200 }}>
                Guardar Cambios
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FinanceView;
