import React, { useState, useRef, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, Grid, Container, TextField, IconButton, Button } from '@mui/material';
import { Assignment, Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Root, RootBody, RootContent, RootItemLine } from './index.style';

const SidebarCard = styled(Card)(({ theme }) => ({
  paddingBottom: '40%',
  marginBottom: theme.spacing(2),
}));

const SidebarTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  marginLeft: theme.spacing(2),
}));

const Checklist = () => {
  const [textFields, setTextFields] = useState(['']);
  const textFieldRefs = useRef([]);

  useEffect(() => {
    textFieldRefs.current = textFieldRefs.current.slice(0, textFields.length);
  }, [textFields]);

  const handleTextChange = (index, event) => {
    const newTextFields = [...textFields];
    newTextFields[index] = event.target.value;
    setTextFields(newTextFields);
  };

  const handleKeyPress = (index, event) => {
    if (event.key === 'Enter') {
      if (index === textFields.length - 1) {
        setTextFields([...textFields, '']);
      }
      setTimeout(() => {
        if (textFieldRefs.current[index + 1]) {
          textFieldRefs.current[index + 1].focus();
        }
      }, 0);
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && textFields[index] === '' && textFields.length > 1) {
      event.preventDefault();
      const newTextFields = [...textFields];
      newTextFields.splice(index, 1);
      setTextFields(newTextFields);
      setTimeout(() => {
        if (textFieldRefs.current[index - 1]) {
          textFieldRefs.current[index - 1].focus();
        }
      }, 0);
    }
  };

  return (
    <Root>
      <RootBody>
        <RootContent>
          <Typography variant='h2'>Tareas</Typography>
          <Button disabled={textFields[0].length === 0} variant='outlined' onClick={() => {}}>Crear</Button>
        </RootContent>
      </RootBody>
      <Grid container>
        <Grid item xs={3}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SidebarCard>
                  <CardContent>
                    <SidebarTitle variant="h6">Hoy</SidebarTitle>
                  </CardContent>
                </SidebarCard>
              </Grid>
              <Grid item xs={6}>
                <SidebarCard>
                  <CardContent>
                    <SidebarTitle variant="h6">Programados</SidebarTitle>
                  </CardContent>
                </SidebarCard>
              </Grid>
              <Grid item xs={6}>
                <SidebarCard>
                  <CardContent>
                    <SidebarTitle variant="h6">Backlog</SidebarTitle>
                  </CardContent>
                </SidebarCard>
              </Grid>
              <Grid item xs={6}>
                <SidebarCard>
                  <CardContent>
                    <SidebarTitle variant="h6">Revisión</SidebarTitle>
                  </CardContent>
                </SidebarCard>
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom>Lista</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Assignment />
                </ListItemIcon>
                <ListItemText />
                <IconButton>
                  <Delete />
                </IconButton>
              </ListItem>
            </List>
          </Container>
        </Grid>
        <Grid item xs={9} container>
          <Grid item xs>
            <Card>
              <CardContent>
                <Grid item xs={12} container>
                  <Grid item xs={1} flexDirection={'column'}>
                    {
                      textFields.map((_, index) => (
                        <Grid item key={index}>
                          <RootItemLine>
                            <hr style={{ width: '10px', margin: 0, padding: 0 }} />
                          </RootItemLine>
                        </Grid>
                      ))
                    }
                  </Grid>
                  <Grid item xs={11} container>

                    {
                      textFields.map((text, index) => (
                        <Grid item xs={11} key={index}>
                          <TextField
                            style={{ height: '40px' }}
                            fullWidth
                            rows={1}
                            variant="standard"
                            value={text}
                            onChange={(event) => handleTextChange(index, event)}
                            onKeyPress={(event) => handleKeyPress(index, event)}
                            onKeyDown={(event) => handleKeyDown(index, event)}
                            inputRef={el => textFieldRefs.current[index] = el}
                            InputProps={{ disableUnderline: true }}
                            placeholder='_'
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Checklist;
