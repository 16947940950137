import { CardContent, Card as CardMUI,  } from '@mui/material'
import React from 'react'

function Card({
    children
}) {
  return (
    <CardMUI elevation={8}>
        <CardContent>
            {children}
        </CardContent>
    </CardMUI>
  )
}

export default Card