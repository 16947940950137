import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { useAuth } from '@/provider/AuthProvider';
import { getFinanceCategories, getFinancePaymentMethods, getFinanceTypes } from '@/api/finance/autoCompleteService';
import { API_URL } from '@/config';

const Form = ({ fetchFinances }) => {
  const [date, setDate] = useState('');
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [income, setIncome] = useState(false);
  const [loading, setLoading] = useState(false);

  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const auth = useAuth();

  const fetchAutoCompleteData = async () => {
    try {
      const typesResponse = await getFinanceTypes();
      setTypes(typesResponse.data.filter(type => type && type.name));

      const categoriesResponse = await getFinanceCategories();
      setCategories(categoriesResponse.data.filter(category => category && category.name));

      const paymentMethodsResponse = await getFinancePaymentMethods();
      setPaymentMethods(paymentMethodsResponse.data.filter(paymentMethod => paymentMethod && paymentMethod.name));
    } catch (error) {
      console.error('Error fetching autocomplete data:', error);
    }
  };
  useEffect(() => {
    fetchAutoCompleteData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(API_URL+ '/finance', {
        date,
        type,
        category,
        amount,
        description,
        payment_method: paymentMethod,
        user_id: auth.user.id,
        income
      });
      console.log('Finance record created:', response.data);
      fetchFinances();
      // Clear form fields
      setDate('');
      setType('');
      setCategory('');
      setAmount(0);
      setDescription('');
      setPaymentMethod('');
      setIncome(false);
      fetchAutoCompleteData();
    } catch (error) {
      console.error('There was an error creating the finance record!', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card elevation={8} style={{ borderRadius: 20 }}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={income}
                    onChange={(e) => setIncome(e.target.checked)}
                    color="primary"
                  />
                }
                label="Es un ingreso"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Fecha"
                fullWidth
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={types.map(option => option.name || '')}
                value={type}
                onChange={(e, newValue) => setType(newValue || '')}
                inputValue={type}
                onInputChange={(e, newInputValue) => setType(newInputValue || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={categories.map(option => option.name || '')}
                value={category}
                onChange={(e, newValue) => setCategory(newValue || '')}
                inputValue={category}
                onInputChange={(e, newInputValue) => setCategory(newInputValue || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categoría"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Monto"
                fullWidth
                type="number"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descripción"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={paymentMethods.map(option => option.name || '')}
                value={paymentMethod}
                onChange={(e, newValue) => setPaymentMethod(newValue || '')}
                inputValue={paymentMethod}
                onInputChange={(e, newInputValue) => setPaymentMethod(newInputValue || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Método de pago"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Guardar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form;
