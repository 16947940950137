import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './Layout/Layout'
import Login from './pages/login/Login'
import Protected from './Layout/Protected/Protected'
import Home from './pages/home'
import token from '@/api/users/token'
import { useAuth } from '@/provider/AuthProvider';
import get from './api/users/get'
import Account from './pages/account'
import Finance from './pages/finance'
import Calendar from './pages/calendar'
import Checklist from './pages/checklist'
import Setting from './pages/setting'
import Notify from './pages/notify'

const App = () => {
    const [isAuth, setIsAuth] = useState(null)
    const [isVerifyAuth, setIsVerifyAuth] = useState(false)

    
    const auth = useAuth()
  
    useEffect(() => {
        if(!isVerifyAuth) {
            setIsVerifyAuth(true)
            void verifyAuth()
        }
    }, [])
    
    const verifyAuth = async () => {
        const localToken = localStorage.getItem('token')
        if (localToken) {
            const res = await token({ token: localToken })
            if (!res) {
                return console.log('Error al verificar token')
            }
            if (!res.data) {
                setIsAuth(false)
                return console.log('Token Incorrecto')
            }
            setIsAuth(true)
            await handleObtainUser(res.data.id)

        } else {
            setIsAuth(false)
        }

    }

    const handleObtainUser = async (id) => {
        console.log("handleObtainUser",id)
        const resUser = await get(id)
        auth.signin(resUser.data, () => { })
    }

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route index element={<Login setIsAuth={setIsAuth} />} />
                <Route element={<Protected isLoggedIn={isAuth} />}>
                    <Route path='home' element={<Home handleObtainUser={handleObtainUser} />} />
                    <Route path="finance" element={<Finance />} />
                    <Route path="checklist" element={<Checklist />} />
                    <Route path="calendar" element={<Calendar />} />
                    <Route path="account" element={<Account />} />
                    <Route path="setting" element={<Setting />} />
                    <Route path="notify" element={<Notify />} />
                </Route>
                <Route path="*" element={<p>No hay nada aquí: 404!</p>} />
            </Route>
        </Routes>
    )
}

export default App