import React from 'react'
import { createContext, useContext, useState } from "react";

interface AuthContextType {
    user: any;
    userML: any;
    signin: (user: string, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
    obtainUserML: (data: any, callback: VoidFunction) => void;
}

let AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    let [user, setUser] = useState<any>(null);
    let [userML, setUserML] = useState<any>(null);
    
    let signin = (newUser: string, callback: VoidFunction) => {
        setUser(newUser);
        callback();
    };

    let signout = (callback: VoidFunction) => {
        localStorage.removeItem('token')
        setUser(null);
        callback();
    };

    let obtainUserML = (data: any, callback: VoidFunction) => {
        setUserML(data);
        callback();
    };


    let value = { user, userML, signin, signout, obtainUserML };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}