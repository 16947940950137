import React from 'react'
import { isActiveClass, Root, RootCard, RootItem } from './MenuMobile.style'
import Card from '@/components/Cards/Card/Card'
import { mainItems } from '../../Sidebar/Sidebar'
import { useLocation, useNavigate } from 'react-router-dom'
import { css } from '@emotion/css'
import { Typography } from '@mui/material'

const MenuMobile = ({ active, setMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (link: string) => {
    return location.pathname === link;
  };
  return (
    <Root>
      <Card>
        <RootCard>
          {mainItems.map((item, index) => (
            <RootItem
              key={index}
              onClick={() => navigate(item.link)}
              className={isActive(item.link) ? css(isActiveClass) : null}>
              <div>
                {item.icon}
              </div>
              {/* <Typography variant="h5">
                <b>{item.text}</b>
              </Typography> */}
            </RootItem>
          ))}
        </RootCard>
      </Card>
    </Root>
  )
}
export default MenuMobile