import { API_URL } from "@/config";
import axios from "axios";

export const getFinanceTypes = () => {
  return axios.get(API_URL + '/finance-types');
};

export const getFinanceCategories = () => {
  return axios.get(API_URL + '/finance-categories');
};

export const getFinancePaymentMethods = () => {
  return axios.get(API_URL + '/finance-payment-methods');
};
