import { separadorMiles } from '@/utils/separadorMiles';
import { Card, Grid, Typography } from '@mui/material';
import React from 'react';

const TotalBalanceWidget = ({ total }) => {

  return (
    <Card style={{borderRadius: 20}} elevation={10}>
      <Grid container mt={3} mb={3}>
        <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} mb={2}>
          <Typography variant='h5'><b>Saldo actual</b></Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
          <Typography variant='h5'>$ {separadorMiles(total)}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TotalBalanceWidget;
