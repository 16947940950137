import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const RootLogOut = styled.div`
  justify-content: flex-end;
  display: flex;
    margin-top: 20px;
    margin-right: 10px;
    border-top: 1px solid rgba(0,0,0,0.1);
    padding-top: 14px;
`
