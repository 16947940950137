import React, { useEffect } from 'react';
import { Root } from './index.style';
import { urlBase64ToUint8Array } from '@/utils/urlBase64ToUint8Array';
import { API_URL } from "@/config";

const Notify = () => {

  useEffect(() => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager.getSubscription().then((subscription) => {
          if (subscription === null) {
            const applicationServerKey = urlBase64ToUint8Array('');
            registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: applicationServerKey
            }).then((newSubscription) => {
              // Envía la suscripción al servidor
              fetch(API_URL + '/notify-subscribe', {
                method: 'POST',
                body: JSON.stringify(newSubscription),
                headers: {
                  'Content-Type': 'application/json'
                }
              });
            }).catch((error) => {
              console.error('Error al suscribir al usuario:', error);
            });
          }
        });
      });
    }
  }, []);

  return (
    <>
      <Root>
        <h1>Notificaciones!!!</h1>
      </Root>
    </>
  );
};

export default Notify;
