import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #D9D9D9;
    height: 100vh;
`
export const RootHeader = styled.div`
    display: flex;
    height: 170px;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    > a {
        margin: 0px;
    }
    > a > svg:first-child {
        width: 234px;
        padding-left: 75px;
    }
    > div > svg {
        width: 100vw;
        padding-top: 15px;
    }
`
export const LogoHeader = styled.img`
    width: 264px;
    object-fit: contain;
`
export const RootContent = styled.div`
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1056px;
    width: 100%;
    margin: auto;
    @media (max-width: ${maxSizeMobile}) {
        padding-left: 0px;
        padding-right: 0px;
        width: 90%;
        margin-top: 85px;
        > h6 {
            font-size: 20px;
        }
        > h2 {
            font-size: 35px;
        }
    }
`

export const RootForm = styled.div`
    width: 100%;
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${maxSizeMobile}) {
        position: relative;
    }
`

export const RootForgot = styled.div`
    z-index: 10;
    cursor: pointer;
    @media (max-width: ${maxSizeMobile}) {
        bottom: -140px;
        right: 0px;
        position: absolute;
    }
`

export const RootButton = styled.div`
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: ${maxSizeMobile}) {
        > a > button {
            font-size: 20px !important;
        }
        margin-top: 36px;
    }
`

export const RootError = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
`


export const ButtonChange = css`
    padding: 15px 35px !important;
    @media (max-width: ${maxSizeMobile}) {
        padding: 12px 20px !important;
        font-size: 16px !important;
        > p {
            font-size: 16px !important;
        }
    }
`

export const RootCreated = styled.div`
   position: fixed;
   bottom: 10px;
   right: 20px;
`