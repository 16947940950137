import React, { useState } from 'react';
import { TextField, Typography, Table, TableBody, TableCell, TableRow, InputBase } from '@mui/material';
import moment from 'moment';
import { separadorMiles } from '@/utils/separadorMiles';
import PencilIcon from '@/components/Icons/PencilIcon';
import styled from '@emotion/styled';

const StyledInput = styled(InputBase)({
  '& .MuiInputBase-input': {
    height: '18px', // Altura definida para el input
    padding: '0 8px', // Añadir un poco de padding para mejorar la usabilidad
    textAlign: 'center'
  },
});


interface EditFieldProps {
  label: string;
  value: string | number | null;
  onSave: (value: string | number) => void;
  type?: string;
  emptyStateMessage?: string;
  isPrice?: boolean;
  isDate?: boolean;
  isM2?: boolean;
  isEdited?: boolean;
}

const EditField: React.FC<EditFieldProps> = ({
  label,
  value,
  onSave,
  type = 'text',
  emptyStateMessage = 'Vacío',
  isPrice = false,
  isDate = false,
  isM2 = false,
  isEdited
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | number | null>(value);

  const handleSave = () => {
    if (currentValue !== value) {
      onSave(currentValue);
    }
    setIsEditing(false);
  };

  const formattedValue = () => {
    if (!value) return emptyStateMessage;
    if (isDate) return moment(value).format('DD/MM/YYYY');
    if (isM2) return `${value} m²`;
    if (isPrice) return separadorMiles(value);
    return value;
  };

  return (
    <Table style={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell style={{ width: '50%', textAlign: 'center', borderRight: '1px solid rgba(0,0,0,00.2)' }}>
            <Typography variant="body1" component="div" onClick={() => setIsEditing(true)} style={{ cursor: 'pointer' }}>
              <b>{label}: </b>
              {isEdited && <PencilIcon style={{ width: 15, fill: 'red' }} />}
            </Typography>
          </TableCell>
          <TableCell style={{ width: '50%', textAlign: 'center' }}>
            {isEditing ? (
              <StyledInput
                type={type}
                value={currentValue}
                onChange={(e) => setCurrentValue(e.target.value)}
                onBlur={handleSave}
                autoFocus
                fullWidth
              />
            ) : (
              <Typography variant="body1" component="div" onClick={() => setIsEditing(true)} style={{ cursor: 'pointer', opacity: !value ? 0.3 : 1 }}>
                {isPrice && '$ '}
                {formattedValue()}
              </Typography>
            )}
            {/* {!isEditing ? (
              <Typography variant="body1" component="div" onClick={() => setIsEditing(true)} style={{ cursor: 'pointer', opacity: !value ? 0.3 : 1 }}>
                {isPrice && '$ '}
                {formattedValue()}
              </Typography>
            ) : (
              <TextField
                type={type}
                value={currentValue || ''}
                onChange={(e) => setCurrentValue(e.target.value)}
                onBlur={handleSave}
                autoFocus
                fullWidth
              />
            )} */}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default EditField;
