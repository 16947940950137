import React from 'react'

const PencilIcon = ({ fill, ...props }:any) => {
  return (
    <svg {...props} viewBox="0 0 18 18">
      <mask id="path-1-inside-1_530_7353" fill="white">
        <path d="M17.71 4.03859C18.1 3.64859 18.1 2.99859 17.71 2.62859L15.37 0.288594C15 -0.101406 14.35 -0.101406 13.96 0.288594L12.12 2.11859L15.87 5.86859M0 14.2486V17.9986H3.75L14.81 6.92859L11.06 3.17859L0 14.2486Z" />
      </mask>
      <path d="M17.71 4.03859C18.1 3.64859 18.1 2.99859 17.71 2.62859L15.37 0.288594C15 -0.101406 14.35 -0.101406 13.96 0.288594L12.12 2.11859L15.87 5.86859M0 14.2486V17.9986H3.75L14.81 6.92859L11.06 3.17859L0 14.2486Z" fill={fill} />
      <path d="M17.71 2.62859L17.0029 3.3357L17.0122 3.345L17.0217 3.35406L17.71 2.62859ZM15.37 0.288594L14.6445 0.976854L14.6536 0.986398L14.6629 0.9957L15.37 0.288594ZM13.96 0.288594L14.6652 0.997627L14.6671 0.995701L13.96 0.288594ZM12.12 2.11859L11.4148 1.40956L10.7039 2.11666L11.4129 2.8257L12.12 2.11859ZM0 14.2486L-0.707426 13.5418L-1 13.8346V14.2486H0ZM0 17.9986H-1V18.9986H0V17.9986ZM3.75 17.9986V18.9986H4.16448L4.45743 18.7054L3.75 17.9986ZM14.81 6.92859L15.5174 7.63538L16.2239 6.92827L15.5171 6.22149L14.81 6.92859ZM11.06 3.17859L11.7671 2.47149L11.0597 1.76406L10.3526 2.47181L11.06 3.17859ZM18.4171 4.7457C19.187 3.97578 19.2145 2.67753 18.3983 1.90313L17.0217 3.35406C17.0121 3.34494 17.0065 3.33525 17.0039 3.32907C17.0018 3.32383 17.0025 3.32285 17.0025 3.32609C17.0025 3.32932 17.0018 3.33219 17.0012 3.33357C17.001 3.33418 17.0015 3.33289 17.0029 3.33149L18.4171 4.7457ZM18.4171 1.92149L16.0771 -0.418513L14.6629 0.9957L17.0029 3.3357L18.4171 1.92149ZM16.0955 -0.399667C15.3211 -1.21592 14.0228 -1.18843 13.2529 -0.418513L14.6671 0.995701C14.6657 0.997101 14.6644 0.997613 14.665 0.997366C14.6664 0.996815 14.6693 0.996094 14.6725 0.996094C14.6757 0.996094 14.6748 0.996797 14.6695 0.99465C14.6633 0.99212 14.6537 0.986469 14.6445 0.976854L16.0955 -0.399667ZM13.2548 -0.420437L11.4148 1.40956L12.8252 2.82762L14.6652 0.997624L13.2548 -0.420437ZM11.4129 2.8257L15.1629 6.5757L16.5771 5.16149L12.8271 1.41149L11.4129 2.8257ZM-1 14.2486V17.9986H1V14.2486H-1ZM0 18.9986H3.75V16.9986H0V18.9986ZM4.45743 18.7054L15.5174 7.63538L14.1026 6.22181L3.04257 17.2918L4.45743 18.7054ZM15.5171 6.22149L11.7671 2.47149L10.3529 3.8857L14.1029 7.6357L15.5171 6.22149ZM10.3526 2.47181L-0.707426 13.5418L0.707426 14.9554L11.7674 3.88538L10.3526 2.47181Z" fill={fill} mask="url(#path-1-inside-1_530_7353)" />
    </svg>

  )
}

export default PencilIcon