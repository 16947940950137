import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RootLogOut } from './MenuExpand.style'
import { useAuth } from '@/provider/AuthProvider'

const MenuExpand = ({ logOut, setOpenModalPerfilML }) => {
    const [isLink, setIsLink] = useState(false)


    const auth = useAuth()

    const checkLink = () => {
        if (auth.user.mercadolibre) {
            setIsLink(true)
        }
    }
    useEffect(() => {
        if (auth.user) {
            checkLink()
        }
    }, [auth.user])
    return (
        <div>           
            <RootLogOut>
                <a onClick={logOut} style={{ cursor: 'pointer' }}>
                    <Typography variant='subtitle1' style={{ marginTop: 4, color: 'black' }}>
                        Cerrar sesión
                    </Typography>
                </a>
            </RootLogOut>
        </div>
    )
}

export default MenuExpand