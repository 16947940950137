import { css } from "@emotion/react";
import styled from "@emotion/styled"

export const Root = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
`
export const RootItem = styled.li`
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;
`;
export const RootCard = styled.div`
    display: flex;
`;
export const isActiveClass = css`
    position: relative;
    z-index: 1;
    opacity: 1 !important;
`;