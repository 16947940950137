import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CircularProgress,
  Paper,
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PencilIcon from '@/components/Icons/PencilIcon';


const Table = ({
  loading,
  users,
  onClickPencil
}) => {

  if (loading) {
    return (
      <RootEmpty>
        <CircularProgress />
      </RootEmpty>
    );
  }

  if (users.length === 0) {
    return (
      <RootEmpty>
        <Typography variant="subtitle2">No tienes propiedades...</Typography>
      </RootEmpty>
    );
  }

  return (
    <Root>
      <Card elevation={8}>
        <TableContainer component={Paper}>
          <TableMui stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell><Typography variant="body2">ID</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Email</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Nombre</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Apellido</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Tipo de Usuario</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Fecha de Creación</Typography></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <StyledTableRow key={user.id}>
                  <StyledTableCell component="th" scope="row"><Typography variant="body2">{user.id}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{user.email}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{user.first_name}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{user.last_name}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{new Date(user.created).toLocaleDateString()}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{new Date(user.created).toLocaleDateString()}</Typography></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableMui>
        </TableContainer>
      </Card>
    </Root>
  );
};

export default Table;

const Root = styled('div')({
  // Your Root styles here
});

const RootEmpty = styled('div')({
  // Your RootEmpty styles here
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: 'relative',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
