import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    display: flex;
    min-height: 130px;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: white;
    position: relative;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${maxSizeMobile}) {
       min-height: 140px;
    }
`

export const RootLogo = styled.div`
margin-left: 20px;
margin-bottom: 10px;
    
`

export const RootBarline = styled.div`
 display: flex;
        position: absolute;
        bottom: 0px;
        width: 100%;
    > svg {
        width: 100%;
        padding-top: 15px;
    }
`

export const RootPerfilMenu = styled.div`
    display: flex;
    margin-right: 15px;
    align-items: center;
    cursor: default;
    > div {
        text-align: right;
        margin-right: 15px;
    }
    @media (max-width: ${maxSizeMobile}) {
        padding-right: 20px;
    }
`

export const RootPerfilContainer = styled.div`
    @media (max-width: ${maxSizeMobile}) {
        display: none !important;
    }
`


export const MenuMobilePerfilMenu = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: red;
`
export const toolTipPopper = css`
   background-color: white !important;
   border-radius: 4px;
   box-shadow: 0px 5px 2px rgba(0,0,0,.4);
   padding-top: 20px !important;
   padding-bottom: 20px !important;
`

