import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import {
  Outlet,
} from "react-router-dom";
import { theme } from '../theme';
import { Root } from './Layout.style';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const version = '0.1.5'

const Layout = () => {

  const clearCacheData = () => {
    let cacheDelStorage = localStorage.getItem('cache-del')
    if (!cacheDelStorage) {
      window.location.reload()
      localStorage.setItem('cache-del', version)
    } else {
      if (cacheDelStorage !== version) {
        window.location.reload()
        localStorage.setItem('cache-del', version)
      }
    }
  };

  useEffect(() => {
    void clearCacheData()
  }, [])

  return (
    <>
      <Root>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <Outlet />
          </ThemeProvider>
        </LocalizationProvider>
      </Root>
    </>
  )
}

export default Layout