import React, { useEffect, useState } from 'react'
import { Root, RootBody, RootContent, RootModal, RootSideBody, RootsideBodyTop, RootTable } from './Finance.style'
import { useAuth } from '@/provider/AuthProvider'
import { Button, Grid, Modal, Snackbar, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import TableOrders from '@/modules/home/TableOrders/TableOrders'
import Table from '@/modules/finance/Table/Table'
import Form from '@/modules/finance/Form/Form'
import axios from 'axios'
import MenuIcon from '@/components/Icons/MenuIcon'
import CloseIcon from '@/components/Icons/CloseIcon'
import WindowIcon from '@/components/Icons/WindowIcon'
import RightScreenIcon from '@/components/Icons/RightScreenIcon'
import FinanceView from '@/modules/finance/FinanceView/FinanceView'
import FinanceEdit from '@/modules/finance/FinanceEdit/FinanceEdit'
import Dashboard from '@/modules/finance/Dashboard/Dashboard'
import { API_URL } from '@/config'

const Finance = () => {
  const [finances, setFinances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFinance, setSelectedFinance] = useState(null);
  const [activeCreate, setActiveCreate] = useState(false);
  const [inModal, setInModal] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setInModal(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [showDashboard, setShowDashboard] = useState(true);


  const fetchFinances = async () => {
    try {
      const response = await axios.get(API_URL+ '/finance');
      console.log("response", response.data)
      setFinances(response.data);
    } catch (error) {
      console.error('Error fetching finances', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFinances();
  }, []);

  const handleRowClick = async (id) => {
    setSelectedFinance(id);
  };

  const handleClickAdd = () => {
    setActiveCreate(true)
    setSelectedFinance(null)
  }

  const handleOnClose = () => {
    setSelectedFinance(null)
    setActiveCreate(false)
  }


  return (
    <>
      <Root>
        <RootBody>
          {showDashboard ? (
            <Dashboard 
            setShowDashboard={setShowDashboard}
            />
          ) : (
            <FinanceEdit
              activeCreate={activeCreate}
              selectedFinance={selectedFinance}
              inModal={inModal}
              fetchFinances={fetchFinances}
              handleOnClose={handleOnClose}
              setInModal={setInModal}
              setSelectedFinance={setSelectedFinance}
              loading={loading}
              finances={finances}
              handleRowClick={handleRowClick}
              handleClickAdd={handleClickAdd}
            />
          )}
        </RootBody>
      </Root>
    </>
  )
}

export default Finance
