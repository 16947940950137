import Sidebar from '@/modules/global/Sidebar/Sidebar';
import React, { useState, useEffect } from 'react'
import { Navigate, Outlet } from "react-router-dom";
import { Root, RootBody, RootContainer } from './Protected.style';
import MenuHeader from '@/modules/global/MenuHeader/MenuHeader';
import MenuMobile from '@/modules/global/MenuHeader/MenuMobile/MenuMobile';
import useIsMobile from '@/hooks/useIsMobile';

const Protected = ({ isLoggedIn }: any) => {
    const [active, setActive] = useState(false);
    const isMobile = useIsMobile();
    const setMenu = (value: boolean) => {
        setActive(value);
    };

    if (isLoggedIn === false) {
        return <Navigate to="/" replace />;
    }



    return (
        <Root>
            {!isMobile 
            ? <Sidebar />
            : <MenuMobile active={active} setMenu={setMenu} />}
            <RootContainer>
                <MenuHeader />
                <RootBody>
                    <Outlet />
                </RootBody>
            </RootContainer>
        </Root>
    );
}

export default Protected;
