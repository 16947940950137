import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Container,
  Button,
} from '@mui/material';
import EditField from '@/components/Inputs/EditField/EditField';
import ImageEditor from '@/components/Inputs/ImageEditor/ImageEditor';
import { API_URL } from '@/config';


const UserView = ({ id, fetchUsers, onClose }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editFields, setEditFields] = useState<any>({});
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(API_URL+ `/users/${id}`);
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user', error);
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  const handleFieldChange = (field, value) => {
    setEditFields({ ...editFields, [field]: value });
    setUser({ ...user, [field]: value });
  };

  const handleImagesChange = (images) => {
    setEditFields({ ...editFields, img: images[0] }); // Assuming only one profile image
    setUser({ ...user, img: images[0] });
  };

  const handleSubmit = async () => {
    try {
      const sendData = { ...editFields };

      const response = await axios.put(API_URL+ `/users/${id}`, sendData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (editFields.img) {
        const formData = new FormData();
        formData.append('img', editFields.img);
        await axios.post(API_URL+ `/users/${id}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      setUser(response.data);
      setEditFields({});
      setSuccessMessage(true);
      setTimeout(() => setSuccessMessage(false), 3000);
      fetchUsers();
    } catch (error) {
      console.error('Error updating user', error);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!user) {
    return (
      <Container>
        <Typography variant="h5" color='rgba(0,0,0,0.4)'>Selecciona un usuario</Typography>
      </Container>
    );
  }

  return (
    <Card elevation={8} sx={{ borderRadius: 5, maxWidth: 800 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <EditField
            label="Email"
            value={user.email}
            onSave={(value) => handleFieldChange('email', value)}
            isEdited={editFields?.email || null}
          />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EditField
              label="Nombre"
              value={user.first_name}
              onSave={(value) => handleFieldChange('first_name', value)}
              isEdited={editFields?.first_name || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Apellido"
              value={user.last_name}
              onSave={(value) => handleFieldChange('last_name', value)}
              isEdited={editFields?.last_name || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Teléfono"
              value={user.phone}
              onSave={(value) => handleFieldChange('phone', value)}
              isEdited={editFields?.phone || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Fecha de Creación"
              value={user.created}
              onSave={(value) => handleFieldChange('created', value)}
              type="date"
              isDate
              isEdited={editFields?.created || null}
            />
          </Grid>
          <Grid item xs={12} mt={4} mb={4} pl={3} pr={3}>
            <Typography variant="body1" mb={3}><b>Imagen: </b></Typography>
            <ImageEditor
              images={user.img ? [user.img] : []}
              onImagesChange={handleImagesChange}
              getImageUrl={(image) => API_URL+ `/uploads/${image.url}`}
              singleImage
            />
          </Grid>
          <Grid item xs={12} container justifyContent={'flex-end'} mb={3}>
            {Object.keys(editFields).length > 0 && (
              <Typography variant="body2" color="error" marginLeft={2}>
                Necesitas guardar los cambios
              </Typography>
            )}
            {successMessage && (
              <Typography variant="body2" color="success" marginLeft={2}>
                Guardado con éxito
              </Typography>
            )}
          </Grid>
          <Grid container item xs={12} display="flex" alignItems="center" justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="contained" color="inherit" onClick={onClose}>
                Cerrar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSubmit} style={{ width: 200 }}>
                Guardar Cambios
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserView;
