import { spacing, theme } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`

`

export const RootTable = styled.div`
margin-top: ${spacing.vertical}px;
`

export const RootContent = styled.div`
    display: flex;
    justify-content: space-between;
`
export const RootWidget = styled.div`
 display: flex;
    justify-content: space-between;
    width: 100%;
`
export const RootBody = styled.div`
    flex:1;    
`