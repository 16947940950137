import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Typography, Table, TableBody, TableCell, TableRow, InputBase } from '@mui/material';
import PencilIcon from '@/components/Icons/PencilIcon';
import styled from '@emotion/styled';

const StyledInput = styled(InputBase)({
  '& .MuiInputBase-input': {
    height: '18px',
    padding: '0 8px',
    textAlign: 'center'
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
  '& .MuiAutocomplete-listbox': {
    textAlign: 'center',
  },
});

interface EditAutocompleteFieldProps {
  label: string;
  value: string;
  options: string[];
  onSave: (value: string) => void;
  emptyStateMessage?: string;
  isEdited?: boolean;
}

const EditAutocompleteField: React.FC<EditAutocompleteFieldProps> = ({
  label,
  value,
  options,
  onSave,
  emptyStateMessage = 'Vacío',
  isEdited
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const handleSave = () => {
    if (currentValue !== value) {
      onSave(currentValue);
    }
    setIsEditing(false);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Table style={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell style={{ width: '50%', textAlign: 'center', borderRight: '1px solid rgba(0,0,0,0.2)' }}>
            <Typography variant="body1" component="div" onClick={() => setIsEditing(true)} style={{ cursor: 'pointer' }}>
              <b>{label}: </b>
              {isEdited && <PencilIcon style={{ width: 15, fill: 'red' }} />}
            </Typography>
          </TableCell>
          <TableCell style={{ width: '50%', textAlign: 'center' }}>
            {isEditing ? (
              <StyledAutocomplete
                freeSolo
                options={options}
                value={currentValue}
                onChange={(e, newValue: string | null) => setCurrentValue(newValue || '')}
                inputValue={currentValue}
                onInputChange={(e, newInputValue) => setCurrentValue((newInputValue || '').toLowerCase())}
                clearIcon={null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      style: { textAlign: 'center' },
                    }}
                    onBlur={handleSave}
                    autoFocus
                    fullWidth
                  />
                )}
              />
            ) : (
              <Typography variant="body1" component="div" onClick={() => setIsEditing(true)} style={{ cursor: 'pointer', opacity: !value ? 0.3 : 1 }}>
                {value || emptyStateMessage}
              </Typography>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default EditAutocompleteField;
