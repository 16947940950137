import styled from "@emotion/styled";

const colorLine = '#a8a8a8';

export const RootDate = styled.div`
  display: flex;
`;

export const RootTopHead = styled.div`
  justify-content: flex-end;
  display: flex;
`;

export const RootHeader = styled.div`
  display: flex;
  flex: 1;    
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const RootBody = styled.div`
  > div:last-child {
    border-bottom: 1px solid ${colorLine};
  }
`;

export const RootRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid ${colorLine};
    border-right: 1px solid ${colorLine};
    min-height: 100px;
  }
  > div:first-child {
    border-left: 1px solid ${colorLine};
  }
`;

export const RootCell = styled.div`
  display: flex; 
  flex: 1;
`;

export const RootContent = styled.div`
  padding: 10px;
  position: relative;
  width: 100%;
`;

export const RootNumber = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const RootItemEvent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 60px;
  margin-top: 20px;
`;
