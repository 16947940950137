export function separadorMiles(value: any) {
  // if (config.COUNTRY_ID === 226) {
  //   var formatter = value.toLocaleString('en-US');
  //   return formatter !== 'NaN' ? formatter : 0;
  // } else {
  let num = parseInt(value)
  const sep = '.'
  const replace = /^[\.]/
  if (!isNaN(num)) {
    num = num
      .toString()
      .split('')
      .reverse()
      .join('')
      .replace(/(?=\d*\.?)(\d{3})/g, `$1${sep}`)
    num = num
      .split('')
      .reverse()
      .join('')
      .replace(replace, '')
    return num
  }
}
