export const Fn = {
  validatePhone: (phone: string) => /^\d+$/.test(phone),
  validateEmail: (mail: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail),
  hiddenEmail: (mail: string) => {
    const mailFirst = mail.split('@')[0]
    const mailSecond = mail.split('@')[1]
    if(mailFirst.length <= 1 ) {
      return mail
    }
    const qty = Math.floor(mailFirst.length / 2)
    const hideNum = []
    for (let i = 0; i < mailFirst.length; i++) {
      if (i < mailFirst.length - qty) {
        hideNum.push('*')
      } else {
        hideNum.push(mailFirst[i])
      }
    }
    return hideNum.join('') + '@' + mailSecond
  },
  hiddenPhone: (phone: string) => {
    const hideNum = []
    for (let i = 0; i < phone.length; i++) {
      if (i < phone.length - 4) {
        hideNum.push('*')
      } else {
        hideNum.push(phone[i])
      }
    }
    return hideNum.join('')
  }
}