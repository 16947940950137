import React, { useState } from 'react';
import { activeRoot, isActiveClass, Root, RootBottom, RootHeader, RootItem, RootList } from './Sidebar.style';
import MenuIcon from '@/components/Icons/MenuIcon';
import { Typography } from '@mui/material';
import HouseIcon from '@/components/Icons/HouseIcon';
import RoofIcon from '@/components/Icons/RoofIcon';
import UserCogIcon from '@/components/Icons/UserCog';
import MonitorIcon from '@/components/Icons/MonitorIcon';
import { useNavigate, useLocation } from 'react-router-dom';
import { css } from '@emotion/css';
import FinanceIcon from '@/components/Icons/FinanceIcon';
import { CalendarIcon } from '@mui/x-date-pickers';
import CogIcon from '@/components/Icons/CogIcon';

export const mainItems = [
    {
        "icon": <HouseIcon style={{ width: 38 }} />,
        "text": "Inicio",
        "link": "/home"
    },
    // {
    //     "icon": <CalendarIcon style={{ width: 38 }} />,
    //     "text": "Calendario",
    //     "link": "/calendar"
    // },
    // {
    //     "icon": <CalendarIcon style={{ width: 38 }} />,
    //     "text": "Checklist",
    //     "link": "/checklist"
    // },
    {
        "icon": <FinanceIcon style={{ width: 38 }} />,
        "text": "Finanzas",
        "link": "/finance"
    },
    // {
    //     "icon": <UserCogIcon style={{ width: 38 }} />,
    //     "text": "Accesos",
    //     "link": "/account"
    // },
];

const configItems = [
    {
        "icon": <CogIcon style={{ width: 38 }} />,
        "text": "Configuración",
        "link": "/setting"
    }
];

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    console.log("isSidebarOpen",isSidebarOpen)
    const isActive = (link: string) => {
        return location.pathname === link;
    };

    // Función para cambiar la visibilidad del Sidebar
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    
    return (
        <Root className={isSidebarOpen ? css(activeRoot) : null}>
            <RootHeader onClick={toggleSidebar}>
                <MenuIcon style={{ width: 38 }} />
            </RootHeader>
            <RootList>
                <div>
                    {mainItems.map((item, index) => (
                        <RootItem
                            key={index}
                            onClick={() => navigate(item.link)}
                            className={isActive(item.link) ? css(isActiveClass) : null}>
                            <div>
                                {item.icon}
                            </div>
                            <Typography variant="h5">
                                <b>{item.text}</b>
                            </Typography>
                        </RootItem>
                    ))}
                </div>
                <RootBottom>
                    {configItems.map((item, index) => (
                        <RootItem
                            key={index}
                            onClick={() => navigate(item.link)}
                            className={isActive(item.link) ? css(isActiveClass) : null}>
                            <div>
                                {item.icon}
                            </div>
                            <Typography variant="h5">
                                <b>{item.text}</b>
                            </Typography>
                        </RootItem>
                    ))}
                </RootBottom>
            </RootList>
        </Root>
    );
};

export default Sidebar;
