import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Autocomplete,
  Input
} from '@mui/material';
import { API_URL } from '@/config';

const Form = ({ fetchEvents }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [status, setStatus] = useState('');
  const [socialMedia, setSocialMedia] = useState('');
  const [type, setType] = useState('');
  const [note, setNote] = useState('');
  const [userId, setUserId] = useState<any>('');
  const [mediaFiles, setMediaFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [statuses, setStatuses] = useState([]);
  const [socialMedias, setSocialMedias] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchAutoCompleteData = async () => {
      try {
        const [statusesRes, socialMediasRes, typesRes, usersRes] = await Promise.all([
          axios.get(API_URL+ '/event-status'),
          axios.get(API_URL+ '/event-social-media'),
          axios.get(API_URL+ '/event-type'),
          axios.get(API_URL+ '/user')
        ]);

        setStatuses(statusesRes.data);
        setSocialMedias(socialMediasRes.data);
        setTypes(typesRes.data);
        setUsers(usersRes.data);
      } catch (error) {
        console.error('Error al obtener los datos de autocompletado:', error);
      }
    };

    fetchAutoCompleteData();
  }, []);

  const handleFileChange = (e) => {
    setMediaFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const eventResponse = await axios.post(API_URL+ '/event', {
        title,
        description,
        date,
        time,
        status,
        socialMedia,
        type,
        note,
        userId: userId?.id,
      });

      const eventId = eventResponse.data.id;

      const formData = new FormData();
      for (let i = 0; i < mediaFiles.length; i++) {
        formData.append('files', mediaFiles[i]);
      }
      formData.append('eventId', eventId);

      await axios.post(API_URL+ '/event-media/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      fetchEvents();
      setTitle('');
      setDescription('');
      setDate('');
      setTime('');
      setStatus('');
      setSocialMedia('');
      setType('');
      setNote('');
      setUserId('');
      setMediaFiles([]);
    } catch (error) {
      console.error('Hubo un error al crear el evento:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card elevation={8} style={{ borderRadius: 20 }}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Título"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Fecha"
                fullWidth
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Hora"
                fullWidth
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                options={statuses.map(option => option.name)}
                value={status}
                onChange={(e, newValue) => setStatus(newValue || '')}
                onInputChange={(e, newInputValue) => setStatus(newInputValue || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Estado"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                options={socialMedias.map(option => option.name)}
                value={socialMedia}
                onChange={(e, newValue) => setSocialMedia(newValue || '')}
                onInputChange={(e, newInputValue) => setSocialMedia(newInputValue || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Red Social"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                options={types.map(option => option.name)}
                value={type}
                onChange={(e, newValue) => setType(newValue || '')}
                onInputChange={(e, newInputValue) => setType(newInputValue || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Nota"
                fullWidth
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                value={userId}
                onChange={(e, newValue) => setUserId(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsable"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                type="file"
                inputProps={{ multiple: true }}
                onChange={handleFileChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descripción"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Guardar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form;
