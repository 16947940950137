import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
        display: flex;    
`
export const RootContainer = styled.div`
        flex:1; 
`
export const RootBody = styled.div`
        flex:1
`