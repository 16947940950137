import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CircularProgress,
  Paper,
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PencilIcon from '@/components/Icons/PencilIcon';
import { separadorMiles } from '@/utils/separadorMiles';
import moment from 'moment';

const Table = ({
  loading,
  finances,
  onClickPencil
}) => {

  if (loading) {
    return (
      <RootEmpty>
        <CircularProgress />
      </RootEmpty>
    );
  }

  if (finances.length === 0) {
    return (
      <RootEmpty>
        <Typography variant="subtitle2">No hay datos financieros...</Typography>
      </RootEmpty>
    );
  }

  return (
    <Root>
      <Card elevation={8} style={{ borderRadius: 20 }}>
        <TableContainer component={Paper}>
          <TableMui stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell><Typography variant="body2">ID</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Fecha</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Tipo</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Categoría</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Monto</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Descripción</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Método de pago</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Inversión</Typography></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finances.map((finance) => (
                <StyledTableRow key={finance.id}>
                  <StyledTableCell component="th" scope="row"><Typography variant="body2">{finance.id}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{moment(finance.date).format('DD/MM/YYYY')}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{finance.type}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{finance.category}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">$ {separadorMiles(finance.amount)}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{finance.description}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{finance.payment_method}</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">{finance.income ? 'Si' : 'No'}</Typography></StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body2" onClick={() => onClickPencil(finance.id)}>
                      <PencilIcon style={{ width: 15 }} />
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableMui>
        </TableContainer>
      </Card>
    </Root>
  );
};

export default Table;

const Root = styled('div')({
  // Your Root styles here
});

const RootEmpty = styled('div')({
  // Your RootEmpty styles here
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: 'relative',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
