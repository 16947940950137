import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material';
import { API_URL } from "@/config";

const Form = ({ fetchUsers }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [userType, setUserType] = useState(0);
  const [created, setCreated] = useState('');
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState([]);

  useEffect(() => {
    const fetchUserTypes = async () => {
      try {
        const response = await axios.get(API_URL + '/user-types');
        setUserTypes(response.data);
      } catch (error) {
        console.error('Error fetching user types', error);
      }
    };

    fetchUserTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const userData = {
      email,
      first_name: name,
      last_name: lastName,
      password,
      phone,
      userType,
      created,
      img: img ? img.name : null, // Assuming you want to store the filename
    };

    try {
      const response = await axios.post(API_URL+ '/users', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // If image is provided, upload it separately
      if (img) {
        const formData = new FormData();
        formData.append('img', img);
        await axios.post(`${API_URL}/users/${response.data.id}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      console.log('Usuario creado:', response.data);
      fetchUsers();

      // Clear form fields
      setEmail('');
      setName('');
      setLastName('');
      setPassword('');
      setPhone('');
      setUserType(0);
      setCreated('');
      setImg(null);
    } catch (error) {
      console.error('Hubo un error al crear el usuario!', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card elevation={8} style={{ borderRadius: 20 }}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nombre"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Apellido"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contraseña"
                fullWidth
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Teléfono"
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Tipo de Usuario"
                fullWidth
                select
                value={userType}
                onChange={(e) => setUserType(Number(e.target.value))}
                required
              >
                {userTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name} ({type.description})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Fecha de Creación"
                fullWidth
                type="date"
                value={created}
                onChange={(e) => setCreated(e.target.value)}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
              >
                Subir Imagen
                <input
                  type="file"
                  hidden
                  onChange={(e) => setImg(e.target.files[0])}
                />
              </Button>
              {img && <Typography variant="body2">{img.name}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Crear Usuario'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form;
