import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { RootBody, RootCell, RootContent, RootDate, RootHeader, RootItemEvent, RootNumber, RootRow, RootTopHead } from './CalendarTable.style';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { API_URL } from '@/config';

function CalendarTable({onClick}) {
    const [currentDate, setCurrentDate] = useState(moment());
    const [events, setEvents] = useState([]);

    const startDay = currentDate.clone().startOf('month').startOf('week');
    const endDay = currentDate.clone().endOf('month').endOf('week');

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(API_URL+ '/event'); // Actualiza la URL según tu API
                setEvents(response.data);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, [currentDate]);

    const generateCalendar = () => {
        const day = startDay.clone().subtract(1, 'day');
        const calendar = [];

        while (day.isBefore(endDay, 'day')) {
            calendar.push(
                Array(7).fill(0).map(() => day.add(1, 'day').clone())
            );
        }

        return calendar;
    };

    const handlePrevMonth = () => {
        setCurrentDate(currentDate.clone().subtract(1, 'month'));
    };

    const handleNextMonth = () => {
        setCurrentDate(currentDate.clone().add(1, 'month'));
    };

    const calendar = generateCalendar();

    const getEventsForDay = (day) => {
        return events.filter(event => moment(event.date).isSame(day, 'day'));
    };

    return (
        <div>
            <RootTopHead>
                <RootDate>
                    <button onClick={handlePrevMonth}>Prev</button>
                    <h2>{currentDate.format('MMMM YYYY')}</h2>
                    <button onClick={handleNextMonth}>Next</button>
                </RootDate>
            </RootTopHead>
            <RootHeader>
                <div><span>L</span></div>
                <div><span>M</span></div>
                <div><span>M</span></div>
                <div><span>J</span></div>
                <div><span>V</span></div>
                <div><span>S</span></div>
                <div><span>D</span></div>
            </RootHeader>
            <RootBody>
                {calendar.map((week, index) => (
                    <RootRow key={index}>
                        {week.map((day, idx) => (
                            <RootCell key={idx}>
                                <RootContent>
                                    <RootNumber>{day.format('D')}</RootNumber>
                                    {getEventsForDay(day).map(event => (
                                        <RootItemEvent onClick={() => onClick(event.id)}>
                                            <Item event={event} />
                                        </RootItemEvent>
                                    ))}
                                </RootContent>
                            </RootCell>
                        ))}
                    </RootRow>
                ))}
            </RootBody>
        </div >
    );
}

const Item = ({ event }) => {
    return (
        <Card style={{padding: 10, width:'100%'}}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='body2'>{event.title}</Typography>
                        <Typography variant='caption'>{event.date}</Typography>
                    </Grid>
                    <Grid item xs container justifyContent={'flex-end'}>
                        <Avatar sizes='small' />
                    </Grid>
                </Grid>
        </Card>
    );
}

export default CalendarTable;
