import { spacing, theme } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`
    padding-top: ${spacing.vertical}px;
    padding-bottom: ${spacing.vertical}px;
    padding-left: ${spacing.horizontal}px;
    padding-right: : ${spacing.horizontal}px;
`

export const RootTable = styled.div`
margin-top: ${spacing.vertical}px;
`

export const RootContent = styled.div`

`

export const RootWidget = styled.div`

`
